<template>
  <div>
    <b-modal id="modal-edit-referral-bonus-withdrawal" cancel-title="Отмена"
             centered
             ok-title="Создать"
             title="Редактировать запрос на вывод реферального бонуса"
             @hidden="resetForm"
             @ok="handleOk"
             @reset="resetForm"
             @show="resetForm"
    >

      <b-dropdown id="dropdown-dropright" dropright :text="getStatus(request.status)" variant="primary" class="m-2">
        <b-dropdown-item v-for="s in statuses" href="#" v-bind:key="s.value" @click="request.status = s.value">{{ s.label }}</b-dropdown-item>
      </b-dropdown>
    </b-modal>
  </div>
</template>

<script>

import axios from "axios";
import {mapGetters} from "vuex";

export default {
  name: 'modal-edit-referral-bonus-withdrawal',
  props: ['request'],

  data() {
    return {
      paymentSystem: 'card',
      requisite: '',

      statuses: [
        {value: 'NEW', label: 'Новый'},
        {value: 'ON_PAYMENT', label: 'На выплате'},
        {value: 'PAYED', label: 'Выплачено'},
        {value: 'ERROR', label: 'Ошибка'}
      ]
    }
  },

  computed: {
    ...mapGetters(['token']),
  },

  methods: {
    handleOk: function (bvModalEvt) {

      bvModalEvt.preventDefault()

      let reqBody = {
        id: this.request.id,
        status: this.request.status
      };

      axios.post('/api/admin/referral_withdrawals', reqBody,
          {
            headers: {
              'Authorization': 'Bearer ' + this.token
            },
            params: {
              id: this.request.id,
              status: this.request.status
            }
          }
      ).then(
          () => {
            alert("Статус изменен!")
            this.$nextTick(() => {
              this.$bvModal.hide('modal-referral-bonus-withdrawal')
            })
          }
      );
    },

    resetForm: function () {
      this.paymentSystem = '';
      this.requisite = '';
    },

    getStatus: function (value) {
      return this.statuses.filter(e => {
            return e.value === value
          }
      )[0].label
    },
  },

}
</script>

