<template>
  <div>
    <ModalEditReferralBonusWithdrawal :request="currentRequest"></ModalEditReferralBonusWithdrawal>
    <table class="table table-striped">
      <thead>
      <tr>
        <th scope="col">Id</th>
        <th scope="col">Email</th>
        <th scope="col">Сумма</th>
        <th scope="col">Платежная система</th>
        <th scope="col">Реквизиты</th>
        <th scope="col">Статус</th>
        <th scope="col">Дата</th>
        <th scope="col"></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="r in history.content" v-bind:key="r.id">
        <td>{{ r.id }}</td>
        <td>{{ r.user.username }}</td>
        <td>{{ r.value }}</td>
        <td>{{ r.paymentSystem }}</td>
        <td>{{ r.requisites }}</td>
        <td>
          <span v-if="r.status === 'NEW'" class="badge badge-pill badge-primary">Новое</span>
          <span v-if="r.status === 'ON_PAYMENT'" class="badge badge-pill badge-info">На выплате</span>
          <span v-else-if="r.status === 'PAYED'" class="badge badge-pill badge-success">Выплачено</span>
          <span v-else-if="r.status === 'ERROR'" class="badge badge-pill badge-danger">Ошибка</span>
        </td>
        <td>{{ r.createdDate | moment("DD.MM.YYYY HH:mm:ss") }}</td>
        <td>
          <button v-b-modal.modal-edit-referral-bonus-withdrawal class="btn-link" @click="currentRequest = r"> ✏️</button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import axios from "axios";
import ModalEditReferralBonusWithdrawal from "@/components/modal/EditReferralBonusWithdrawalForm";

export default {
  name: 'RequestHistory',
  components: {ModalEditReferralBonusWithdrawal},
  props: ['type'],

  data: function () {
    return {
      history: {},
      currentRequest: {},
    }
  },

  computed: {
    ...mapGetters(['user', 'token']),
  },

  watch: {
    type: function () {
      this.fetch()
    }
  },

  methods: {
    ...mapActions(["setMaintaining"]),
    fetch: function () {
      axios.get('/api/admin/referral_withdrawals', {
        headers: {
          'Authorization': 'Bearer ' + this.token
        }
      }).then(
          response => {
            this.history = response.data
          }
      );
    },
    async approveRequest(id) {

      const res = await axios.post('/api/admin/request/' + id + '/approve', null, {
        headers: {
          'Authorization': 'Bearer ' + this.token
        }
      })
      await res.data
    },
  },

  created() {

    if (!this.user.roles.includes('ADMIN')) {
      this.$router.push('/')
    }
    this.fetch()
  }
}
</script>